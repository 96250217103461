<template>
  <b-tabs>
    <b-tab active title="Orders">
       <order :orderData="orderData" />
    </b-tab>

    <b-tab active title="Components">
       <component :componentData="componentData" />
    </b-tab>

    <b-tab active title="Inventory">
      <inventory :inventoryData="inventoryData" />
   </b-tab>
    
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import Machine from './machine.vue'
import Order from './orders.vue'
import Component from './components.vue'
import Inventory from './inventory.vue'
export default {
  components: {
    BTabs,
    BCardText,
    BTab,

    Machine, 
    Order,
    Component,
    Inventory
  },
  props: {
    componentData: {
      type: [Array, Object],
      default: () => {},
    },
    orderData: {
      type: [Array, Object],
      default: () => {},
    },
    inventoryData: {
      type: [Array, Object],
      default: () => {},
    },
  },
}
</script>