<template>
  <b-card
    no-body
  >

  <add-new
      :is-add-new-component-sidebar-active.sync="isAddNewComponentSidebarActive" 
      :oldComponents="componentData" 
      :pageTitle="pageTitle" 
    />

    <b-button
        @click="addNewData"
        variant="outline-primary"
        class="mb-2"
      >
        Add {{ pageTitle}}
    </b-button>

    <b-table
      striped
      responsive
      :items="componentData"
      :fields="tableColumns"
      class="mb-0"
    >

      <template #cell(id)="data">
          {{data.index + 1}}
      </template>

      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import AddNew from './AddComponent.vue'
export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, AddNew, BButton,
  },
  props: {
    componentData: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data(){
      return {
            tableColumns: [
                  { key: 'id', sortable: true },
                  { key: 'name', label: 'Name', sortable: true },
                  { key: 'created_at', label: 'Created At', sortable: true },
                //   { key: 'created_by', label: 'Created By', sortable: true },
                //   { key: 'actions' },
            ],
            pageTitle: 'component',
            isAddNewComponentSidebarActive: false,

      }
  },
  methods: {
    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    toggleDataSidebar(val=false) {
      this.isAddNewComponentSidebarActive = val
    },
    refreshData(newData){
      this.componentData = newData
    }
  }

}
</script>

<style>

</style>
