<template>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
  <b-card>
    
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.customers_image"
            :text="userData.first_name"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <!-- <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
            </div> -->
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="DollarSignIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.orders_count }}
              </h5>
              <small>All Orders</small>
            </div>
          </div>


          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="CheckCircleIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.complat_orders_count }}
              </h5>
              <small>Complated</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-warning"
              rounded
            >
              <feather-icon
                icon="XOctagonIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.cancel_orders_count }}
              </h5>
              <small>Canceled</small>
            </div>
          </div>


          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="ClockIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ userData.pending_orders_count }}
              </h5>
              <small>Pending</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Englih Name</span>
            </th>
            <td class="pb-50">
              {{ userData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Arabic Name</span>
            </th>
            <td class="pb-50">
              {{ userData.name_ar }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
                  <b-badge @click="changeStatus(userData.id)" :variant="status[1][userData.status]" style="cursor: pointer;">
                        {{ status[0][userData.status] }}
                  </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Role</span>
            </th>
            <td class="pb-50 text-capitalize">
              Workshop
            </td>
          </tr>
          
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Phone</span>
            </th>
            <td>
              {{ userData.phone }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>

  <b-card>
    <tab  :orderData="orderData" :componentData="componentData" :inventoryData="inventoryData"/>
  </b-card>

</b-overlay>

</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge, BOverlay
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DataService  from './data/services'
import Tab from './profile/tab'
export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge, BOverlay,
    ToastificationContent,
    Tab
  },

  data() {
    return {
      userData: {},
      machineData: [],
      orderData: [],
      componentData: [],
      inventoryData: [],
      isLoading: true,
      status: [
        {
          1: 'Actvie', 0: 'Inactive',
        },
        {
          1: 'light-success', 0: 'light-danger', 
        }
      ],
    }
  },
  methods: {
      getData(){
            this.isLoading = true;
            DataService.get(this.$route.params.id)
            .then(response => {
                  this.isLoading = false
                  this.userData    = response.data.data.workshop
                  // this.machineData = response.data.data.customer_machine
                  this.orderData   = response.data.data.order
                  this.componentData = response.data.data.workshop.component
                  this.inventoryData = response.data.data.inventory
                  this.$toast({
                        component: ToastificationContent,
                        props: {
                              title: 'successfully',
                              icon: 'BellIcon',
                              text: response.data.responseDescription,
                              variant: 'success',
                        },
                  })
            })
      },
      changeStatus(id){
            this.isLoading = true;
            DataService.changeStatus(id)
            .then(response => {
            this.isLoading = false
            if(this.userData.status == 1){
                  this.userData.status = 0
            }else{
                  this.userData.status = 1
            }
            this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: 'successfully',
                  icon: 'BellIcon',
                  text: response.data.responseDescription,
                  variant: 'success',
                  },
            })
            
            
            })
      },
      
  },
  mounted() {
    this.getData()
  },
  
}
</script>

<style>

</style>
