<template>
  <b-card
    no-body
  >

    <b-table
      striped
      responsive
      :items="machineData"
      :fields="tableColumns"
      class="mb-0"
    >

      <template #cell(id)="data">
          {{data.index + 1}}
      </template>

      <template #cell(created_at)="data" >
          {{data.value | moment('YY-MM-DD HH:mm:ss')}}
      </template>

    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,
  },
  props: {
    machineData: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data(){
      return {
            tableColumns: [
                  { key: 'id', sortable: true },
                  { key: 'type', label: 'Type', sortable: true },
                  { key: 'year', label: 'Year', sortable: true },
                  { key: 'brand.name', label: 'Brand', sortable: true },
                  { key: 'machine.name', label: 'Machine', sortable: true },
                  { key: 'created_at', label: 'Created At', sortable: true },
                  { key: 'actions' },
            ],
      }
  }

}
</script>

<style>

</style>
